import React from 'react';
import Layout from '../components/Layout'
import { Container, Row, Col } from 'reactstrap';
import Banner from '../images/PrivacyPolicy-banner.jpg'
import Story from '../images/About/banner3.jpeg'
import "firebase/auth";


function PrivacyPolicy() {
    return (
        <Layout>
            <section className="story_banner">
                <Container>
                    <Row>
                        <Col md="12">
                <img src={Banner} alt="img" />
                </Col>
                </Row>
                </Container>
            </section>
            <section className="story_section">
                <Container>
                    <Row>
                        <Col md="12">
                           <div>
                               <p>Your privacy is important to us, and therefore we hereby regulate the manner of collecting, storing and using Your personal data which You voluntarily provide as a user accessing our website (www.greyspacefurniture.com).</p>
                               <p>For the purposes of these terms, personal data are all data relating to an individual private or legal entity, by which it can be directly or indirectly identified. GREY SPACE is processing Your personal data in a lawful, fair and transparent manner solely and exclusively for the purpose for which it was collected and the terms apply only to personal data collected from online sources that contain a link to our website. Collecting personal data from any other source is excluded.</p>
                               <p>GREY SPACE collects your personal information in order to promote and sell its products and services. For example, GREY SPACE may collect and use Your personal data for purposes of providing information on products and services, distributing promotional messages about other products and services and for communicating with users.</p>
                               <p>In the event that GREY SPACE intends to process personal data for purposes other than one for which Your personal information was collected, GREY SPACE will contact and inform You furthermore of the other purpose of processing Your personal data, along with all other relevant information. However, GREY SPACE reserves the right in certain cases to carry out additional processing of data to the extent permitted or prescribed by law.</p>
                               <p>By accessing our website, Facebook, Instagram or You tube profile, You give the consent for collecting and using Your personal information in accordance with the terms and conditions set forth herein, and You agree that GREY SPACE may, at its sole discretion, modify, supplement, delete or in any way update the terms of protection of Your personal data. GREY SPACE will make all changes visible on its web site with the indication of personal data that is collected and the means and purposes for processing them, as well as the conditions under which personal data can be inspected.</p>
                               <p>You can check at any time whether Your personal information is up to date and accurate, or at any time You can request from GREY SPACE access to your personal information that is collected, corrected or deleted, as well as request the limitation of processing Your personal data. Also, at any time you can revoke your consent to process your personal data, request that we stop sending You email newsletters and notifications to your address, as well as make a complaint about the current personal data collection. Please send your written request by e-mail to info@greyspacefurniture.com. We will resolve your request quickly and in an appropriate manner.</p>
                               <p>You can access our website without registration or registration, without disclosing Your identity. However, to access some of our services, it is necessary to fill out forms with certain personal data. If You do not want to provide the said personal data, You will not be able to access the requested pages and GREY SPACE will not be able to answer your request.</p>
                               <p>GREY SPACE expressly declares that will not sell, share or otherwise transfer Your personal data to third parties, except under the terms and in the manner determined by these terms. GREY SPACE reserves the right to disclose Your personal data to third parties who are hired by GREY SPACE to provide certain services on behalf of GREYSPACE or/and in connection with other GREY SPACE business activities, solely for the purposes in which the personal data were originally collected and for which You have given Your consent, for what GREY SPACE will pay particular attention to.</p>
                               <p>To protect the security and confidentiality of the user personal data we collect online, we use the prescribed security technologies and procedures, and all data is password-protected. GREY SPACE takes all necessary steps to protect your personal data from hazards such as loss, abuse, unauthorized access, destruction, etc. However, we do not warrant or represent that your personal information is completely safe from hackers and other security threats</p>
                               <p>GREY SPACE can collect and process anonymous data obtained by Your access to our website and performed searches. Such anonymous data is used for internal purposes of product and service improvements, as well as for the internal statistics purposes.</p>
                           </div>
                        </Col>
                        
                    </Row>
                </Container>
            </section>
        </Layout>
    );
}

export default PrivacyPolicy;
